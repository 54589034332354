import { useEffect, useState } from "react";
import "./landingPage.css";
import { Button, Table} from "react-bootstrap";
import axios from "axios";
import EditData3 from "./EditData3";
import FilterMenu3 from "./FilterMenu3";
import { useClipboard } from "use-clipboard-copy";

const IsiTabelBerkas3 = () => {
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [searchTerm1, setSearchTerm1] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);

  const clipboard = useClipboard();

  useEffect(() => {
    fetch("http://api.databoqti.my.id/sto_boq_bag3")
      .then((res) => res.json())
      .then((data) => {
        const updatedData = data.map((d) => {
          const duplicates = data.filter(
            (item) => item.internet_bag3 === d.internet_bag3
          );
          if (duplicates.length > 1) {
            const dates = duplicates.map((item) => new Date(item.tanggal_bag3));
            const maxDate = new Date(Math.max.apply(null, dates));
            const minDate = new Date(Math.min.apply(null, dates));
            const monthsDifference =
              (maxDate.getFullYear() - minDate.getFullYear()) * 12 +
              maxDate.getMonth() -
              minDate.getMonth();

            if (monthsDifference >= 0 && monthsDifference <= 3) {
              return { ...d, gangguan: "★" };
            } else if (monthsDifference > 3 && monthsDifference <= 6) {
              return { ...d, gangguan: "★★" };
            } else if (monthsDifference > 6) {
              return { ...d, gangguan: "★★★" };
            }
          }
          return d;
        });

        setData(updatedData);
      })
      .catch((err) => console.log(err));
  }, []);

  //Fungsi Checklist All
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (searchTerm1 === "" && searchTerm2 === "") {
      data.forEach((d) => (d.selected = !selectAll));
    } else {
      filteredData.forEach((d) => (d.selected = !selectAll));
    }
  };

  //Fungsi Checklist

  const handleSelect = (tiket_bag3) => {
    const newData = [...data];
    const index = newData.findIndex((d) => d.tiket_bag3 === tiket_bag3);
    newData[index].selected = !newData[index].selected;
    setData(newData);
    setSelectAll(newData.every((d) => d.selected));
  };

  // Pop-Up Modal Edit
  const handleEdit = (data) => {
    setSelectedData(data);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  // Pop-Up Modal Filter
  const handleFilter = () => {
    setShowFilterModal(true);
  };

  const handleCloseFilter = () => {
    setShowFilterModal(false);
  };

  // SaveUser dari Component EditData
  const SaveUser = async (data) => {
    try {
      await axios.patch(
        `http://api.databoqti.my.id/sto_boq_bag3/${data.tiket_bag3}`,
        {
          tiket_bag3: data.tiket_bag3,
          tanggal_bag3: data.tanggal_bag3,
          internet_bag3: data.internet_bag3,
          odp_bag3: data.odp_bag3,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Fungsi Delete Data
  const handleDelete = (dataToDelete) => {
    axios
      .delete(`http://api.databoqti.my.id/sto_boq_bag3/${dataToDelete.tiket_bag3}`)
      .then((res) => {
        if (res.status === 200) {
          // Filter out the deleted data from state
          const newData = data.filter(
            (d) => d.tiket_bag3 !== dataToDelete.tiket_bag3
          );
          setData(newData);
        }
      })
      .catch((err) => console.log(err));
  };

  // Fungsi Delete Selected Data
  const handleDeleteSelected = () => {
    // Get the tiket_bag3 values of the selected data
    const selectedTikets = data
      .filter((d) => d.selected)
      .map((d) => d.tiket_bag3);

    // Create a promise for each delete operation
    const deletePromises = selectedTikets.map((tiket) =>
      axios.delete(`http://api.databoqti.my.id/sto_boq_bag3/${tiket}`)
    );

    // Execute all delete operations concurrently
    Promise.all(deletePromises)
      .then(() => {
        // Filter out the deleted data from state
        const newData = data.filter(
          (d) => !selectedTikets.includes(d.tiket_bag3)
        );
        setData(newData);
      })
      .catch((err) => console.log(err));
  };

  // Fungsi refresh data
  const handleRefresh = () => {
    fetch("http://api.databoqti.my.id/sto_boq_bag3")
      .then((res) => res.json())
      .then((data) => setData(data))
      .catch((err) => console.log(err));
  };

  // Fungsi Search
  const handleSearch1 = (event) => {
    setSearchTerm1(event.target.value);
  };

  const handleSearch2 = (event) => {
    setSearchTerm2(event.target.value);
  };

  // Fungsi Filter Data
  const filteredData = data
    .filter((item) =>
      searchTerm2 === ""
        ? item.tiket_bag3.toLowerCase().includes(searchTerm1.toLowerCase()) ||
          new Date(item.tanggal_bag3)
            .toLocaleDateString("en-CA")
            .includes(searchTerm1) ||
          item.internet_bag3
            .toLowerCase()
            .includes(searchTerm1.toLowerCase()) ||
          item.odp_bag3.toLowerCase().includes(searchTerm1.toLowerCase())
        : item.tiket_bag3.toLowerCase().includes(searchTerm1.toLowerCase()) ||
          new Date(item.tanggal_bag3)
            .toLocaleDateString("en-CA")
            .includes(searchTerm1) ||
          item.internet_bag3
            .toLowerCase()
            .includes(searchTerm1.toLowerCase()) ||
          item.odp_bag3.toLowerCase().includes(searchTerm1.toLowerCase()) ||
          item.tiket_bag3.toLowerCase().includes(searchTerm2.toLowerCase()) ||
          new Date(item.tanggal_bag3)
            .toLocaleDateString("en-CA")
            .includes(searchTerm2) ||
          item.internet_bag3
            .toLowerCase()
            .includes(searchTerm2.toLowerCase()) ||
          item.odp_bag3.toLowerCase().includes(searchTerm2.toLowerCase())
    )
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleCopy = () => {
      // Get the selected data from the filteredData instead of data
      const selectedData = filteredData.filter(d => d.selected);
      
      // Convert the selected data into a format that can be pasted into Excel
      const excelFormattedData = selectedData.map(d => {
        let modifiedD = {...d};
        // Create a new date at midday to prevent timezone issues
        let date = new Date(d.tanggal_bag3);
        date.setHours(12, 0, 0, 0);
        modifiedD.tanggal_bag3 = date.toISOString().split('T')[0];
        return Object.values(modifiedD).join('\t');
      }).join('\n');
      
      // Copy the Excel-formatted data to the clipboard
      if (clipboard.isSupported()) {
        clipboard.copy(excelFormattedData);
        console.log('Data copied to clipboard');
      } else {
        console.error('Failed to copy text: copy-to-clipboard is not supported');
      }
    };

  // Add this function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Generate the page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="container-lg pt-3">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-6">
                <h2>
                  Berkas <b>BOQ - Tabel 3</b>
                </h2>
              </div>
              <div className="col-sm-6">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="search-box mr-1">
                    <input
                      type="text"
                      className="form-control-search"
                      placeholder="Pencarian..."
                      value={searchTerm1}
                      onChange={handleSearch1}
                    />
                  </div>
                  <div className="search-box mr-1">
                    <input
                      type="text"
                      className="form-control-search"
                      placeholder="Opsi Tambahan..."
                      value={searchTerm2}
                      onChange={handleSearch2}
                    />
                  </div>
                  <Button
                    onClick={handleRefresh}
                    type="button"
                    className="btn btn-dark add-new"
                  >
                    {" "}
                    <i className="fa fa-refresh"></i> Refresh{" "}
                  </Button>
                  <Button
                    onClick={handleFilter}
                    type="button"
                    className="btn btn-dark add-new"
                  >
                    {" "}
                    <i className="fa fa-cog"></i> Filter{" "}
                  </Button>
                  <Button
                    onClick={handleDeleteSelected}
                    type="button"
                    className="btn btn-dark add-new"
                  >
                    {" "}
                    <i className="fa fa-trash"></i> Hapus{" "}
                  </Button>
                  <Button
                    onClick={handleCopy}
                    type="button"
                    className="btn btn-dark add-new"
                  >
                    {" "}
                    <i className="fa fa-clone"></i> Copy{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {filteredData.length > 0 ? (
            <Table bordered>
              <thead>
                <tr>
                  <th class="text-center">
                    <span className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="selectAll"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                      <label htmlFor="selectAll"></label>
                    </span>
                  </th>
                  <th>Tiket</th>
                  <th>Tanggal</th>
                  <th>No Internet</th>
                  <th>ODP</th>
                  <th class="text-center">Gangguan</th>
                  <th class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((d, i) => (
                  <tr key={i}>
                    <td class="text-center">
                      <span className="custom-checkbox">
                        <input
                          type="checkbox"
                          id={`checkbox${i}`}
                          name="options[]"
                          value={d}
                          checked={d.selected}
                          onChange={() => handleSelect(d.tiket_bag3)}
                        />
                        <label htmlFor={`checkbox${i}`}></label>
                      </span>
                    </td>
                    <td>{d.tiket_bag3}</td>
                    <td>
                      {new Date(d.tanggal_bag3).toLocaleDateString("en-CA")}
                    </td>
                    <td>{d.internet_bag3}</td>
                    <td>{d.odp_bag3}</td>
                    <td class="text-center">{d.gangguan}</td>
                    <td class="text-center">
                      <a
                        onClick={() => handleEdit(d)}
                        className="edit"
                        title="Edit"
                        data-toggle="tooltip"
                      >
                        <i className="fa fa-info"></i>
                      </a>
                      <a
                        onClick={() => handleDelete(d)}
                        className="delete"
                        title="Delete"
                        data-toggle="tooltip"
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Hasil Pencarian Tidak Ada / Data Kosong</p>
          )}
          <EditData3
  show={showModal}
  handleClose={handleClose}
  data={selectedData}
  AddUser={SaveUser}
/>
          <FilterMenu3
            show={showFilterModal}
            handleClose={handleCloseFilter}
            data={data}
            setData={setData}
          />
        </div>
        <div class="clearfix">
          <div class="hint-text">Nomor Halaman: </div>
          <ul class="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                class={`page-item ${number === currentPage ? "active" : ""}`}
              >
                <a
                  onClick={() => handlePageChange(number)}
                  href="#"
                  class="page-link"
                >
                  {number}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default IsiTabelBerkas3;
