import "./landingPage1.css";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row, Button, FormGroup, FormControl } from "react-bootstrap"
import * as XLSX from 'xlsx';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { read, utils, writeFile } from 'xlsx';

const requiredFields = ["tiket_bag2", "tanggal_bag2", "internet_bag2", "odp_bag2"]

//Bagian Import Data Ke Database
const ExcelImport2 = () => {
  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  }

  // event Import Excel ke API
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('http://api.databoqti.my.id/upload2', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      alert('Data tidak berhasil diupload ke database, data mungkin salah atau kurang tepat');
    }
  }

  //Bagian Read Data Sebelum Ke Database

  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);

  // submit state
  const [excelData, setExcelData] = useState(null);

  const handleFile=(e)=>{
    let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'];
    let selectedFile = e.target.files[0];
    if(selectedFile){
      if(selectedFile&&fileTypes.includes(selectedFile.type)){
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload=(e)=>{
          setExcelFile(e.target.result);
        }
      }
      else{
        setTypeError('Please select only excel file types');
        setExcelFile(null);
      }
    }
    else{
      console.log('Please select your file');
    }
  }

  // submit event
  const handleFileSubmit=(e)=>{
    e.preventDefault();
    if(excelFile!==null){
      const workbook = XLSX.read(excelFile,{type: 'buffer'});
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, {raw: false, dateNF:"YYYY-MM-DD"});
      setExcelData(data.slice(0,1000));
    }
  }
  
  

  return (
    <div className="container-lg pt-3">
    <Fragment>
      <div className="container">
        <div className="col-sm-6 mb-2"><h2>Cek <b>BOQ</b> Kemudian <b>Upload</b></h2></div>
        <Row>
          <Col md="6" className="text-left mb-2">
            <form className="form-group custom-form" onSubmit={handleFileSubmit}>
              <input type="file" className="form-control" required onChange={handleFile} />
              <Button type="submit" className="btn btn-dark add-new mt-2">Cek Data</Button>
              {typeError && (
                <div className="alert alert-danger mt-2" role="alert">{typeError}</div>
              )}
            </form>
          </Col>
          <Col md="6" className="text-left mb-2">
            <FormGroup>
              <FormControl type="file" onChange={handleFileChange} />
              <Button onClick={handleUpload} className="btn btn-dark add-new mt-2">Upload ke Database</Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </Fragment>
  
      <div className="viewer mt-3">
        {excelData ? (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {Object.keys(excelData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {excelData.map((individualExcelData, index) => (
                  <tr key={index}>
                    {Object.keys(individualExcelData).map((key) => (
                      <td key={key}>{individualExcelData[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="TabelCatatan mt-3">
            <h3 className="text-white">Upload Berkas </h3>
            <p className="text-white">Catatan : Berkas Berupa file xlxs.</p>
          </div>
        )}
      </div>
    </div>
  );
}



export default ExcelImport2;