import IsiTabelBerkas from "../components/IsiTabelBerkas";
import Navbar from "../components/Navbars";


const TabelBerkas = () => {
    return (
        <div >
        <Navbar/>
        <IsiTabelBerkas/>
        </div>
    )

}

export default TabelBerkas;