import { useState } from "react";
import ExcelImport from "../components/ExcelImport";
import IsiUploadBerkas from "../components/IsiUploadBerkas";
import Navbar from "../components/Navbars";



const UploadBerkas = () => {
    return (
        <div>
        <Navbar/>
        {/* <IsiUploadBerkas/> */}
        <ExcelImport/>
        </div>
    )

}

export default UploadBerkas;