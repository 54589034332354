import { useEffect, useState } from "react";
import "./landingPage.css";
import { Button, Card, Form, Modal } from "react-bootstrap";

const EditData = ({ show, handleClose, data, AddUser }) => {
  const [tiket, setTiket] = useState(data ? data.tiket : "");
  const [tanggal, setTanggal] = useState(data ? data.tanggal : "");
  const [internet, setInternet] = useState(data ? data.internet : "");
  const [odp, setOdp] = useState(data ? data.odp : "");

  const [duplicateData, setDuplicateData] = useState(null);

  useEffect(() => {
    if (data) {
      setTiket(data.tiket);
      setTanggal(data.tanggal);
      setInternet(data.internet);
      setOdp(data.odp);
    }
  }, [data]);

  useEffect(() => {
    fetch("http://api.databoqti.my.id/sto_boq")
      .then((res) => res.json())
      .then((data) => {
        const updatedData = data.map((d) => {
          const duplicates = data.filter(
            (item) => item.internet === d.internet
          );
          if (duplicates.length > 1) {
            const dates = duplicates.map((item) => new Date(item.tanggal));
            const maxDate = new Date(Math.max.apply(null, dates));
            const minDate = new Date(Math.min.apply(null, dates));
            const monthsDifference =
              (maxDate.getFullYear() - minDate.getFullYear()) * 12 +
              maxDate.getMonth() -
              minDate.getMonth();

            let gangguan;
            if (monthsDifference >= 0 && monthsDifference <= 3) {
              gangguan = "★";
            } else if (monthsDifference > 3 && monthsDifference <= 6) {
              gangguan = "★★";
            } else if (monthsDifference > 6) {
              gangguan = "★★★";
            }

            return { ...d, gangguan };
          }
          return d;
        });

        setDuplicateData(updatedData.filter((d) => d.gangguan)); // Menyimpan data duplikat dengan gangguan
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tiket) {
      alert("Tiket tidak boleh kosong");
      return;
    }
    await AddUser({ tiket, tanggal, internet, odp });
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title >
          <h4 style={{fontWeight: "bold" }}>
            Keterangan Tiket
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            {data && (
              <>
                <p>
                  <strong>No Internet:</strong> {data.internet} <br />
                  <strong>ODP:</strong> {data.odp} <br />
                  <strong>Tanggal:</strong>{" "}
                  {new Date(data.tanggal).toLocaleDateString("en-CA")} <br />
                  <strong>Keterangan Gangguan :</strong>{" "}
                  {data.gangguan
                    ? data.gangguan === "★"
                      ? `Per-3 Bulan (${data.internet})`
                      : data.gangguan === "★★"
                      ? `Per-6 Bulan (${data.internet})`
                      : `Lebih dari 6 Bulan (${data.internet})`
                    : "-"}
                </p>
                <hr class="hr" />
                {duplicateData &&
                  duplicateData
                    .filter((item) => item.internet === data.internet)
                    .map((item, index) => (
                      <p key={index}>
                        <strong>Data Duplikat #{index + 1}:</strong> <br />
                        No Internet: {item.internet} <br />
                        ODP: {item.odp} <br />
                        Tanggal:<strong>{" "}
                        {new Date(item.tanggal).toLocaleDateString("en-CA")}</strong>
                      </p>
                    ))}
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="mt-3">
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label class="font-weight-bold">Tiket</Form.Label>
                <Form.Control
                  type="text"
                  value={tiket}
                  readOnly
                  placeholder="IN121250385"
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label class="font-weight-bold">Tanggal</Form.Label>
                <Form.Control
                  type="date"
                  value={tanggal}
                  onChange={(e) => setTanggal(e.target.value)}
                  placeholder="Contoh: 2023-05-31"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label class="font-weight-bold">No Internet</Form.Label>
                <Form.Control
                  type="text"
                  value={internet}
                  onChange={(e) => setInternet(e.target.value)}
                  placeholder="Contoh: 162504221963"
                />
              </Form.Group >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label class="font-weight-bold">ODP</Form.Label>
                <Form.Control
                  type="text"
                  value={odp}
                  onChange={(e) => setOdp(e.target.value)}
                  placeholder="Contoh: ODP-MLN-FL/31"
                />
              </Form.Group>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button variant="secondary" onClick={handleClose}>
                  Tutup
                </Button>
                <Button variant="dark" type="submit">
                  Simpan Perubahan
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default EditData;
