
import IsiTabelBerkas3 from "../components/IsiTabelBerkas3";
import Navbars3 from "../components/Navbars3";




const TabelBerkas3 = () => {
    return (
        <div>
        <Navbars3/>
        <IsiTabelBerkas3/>
        </div>
    )

}

export default TabelBerkas3;