
// import { Col, Container, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import CaraPenggunaan from './Pages/CaraPenggunaan';
import UploadBerkas from './Pages/UploadBerkas';
import TabelBerkas from './Pages/TabelBerkas';
import NotFoundPage from './Pages/NotFoundPage';
import ExcelImport from './components/ExcelImport';
import TabelBerkas2 from './Pages/TabelBerkas2';
import UploadBerkas2 from './Pages/UploadBerkas2';
import ExcelImport2 from './components/ExcelImport2';
import TabelBerkas3 from './Pages/TabelBerkas3';
import UploadBerkas3 from './Pages/UploadBerkas3';
import ExcelImport3 from './components/ExcelImport2';



function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/Home' element={<Home/>} />
        <Route path='/CaraPenggunaan' element={<CaraPenggunaan/>} />

        {/* Route Tabel 1 */}
        <Route path='/TabelBerkas' element={<TabelBerkas/>} />
        <Route path='/UploadBerkas' element={<UploadBerkas/>} />
        <Route path='/ExcelImport' element={<ExcelImport/>} />

        {/* Route Tabel 2 */}
        <Route path='/TabelBerkas2' element={<TabelBerkas2/>} />
        <Route path='/UploadBerkas2' element={<UploadBerkas2/>} />
        <Route path='/ExcelImport2' element={<ExcelImport2/>} />

        {/* Route Tabel 3 */}
        <Route path='/TabelBerkas3' element={<TabelBerkas3/>} />
        <Route path='/UploadBerkas3' element={<UploadBerkas3/>} />
        <Route path='/ExcelImport3' element={<ExcelImport3/>} />


        <Route path='*' element={<NotFoundPage/>} />

        
      </Routes>
    </Router>
  )
}

export default App;
