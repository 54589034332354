import "./landingPage.css";
import { Container, Row, Col } from 'react-bootstrap';

const IsiCaraPenggunaan = () => {
    return (
        <div>
            <div className="myBG1">
                <Container>
                    <Row className="justify-content-md-center mb-4">
                        <Col md="auto">
                            <img src="images/CaraUpload.png" alt="img" style={{ width: '100%', height: 'auto' }} />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <img src="images/tabelberkas.png" alt="img" style={{ width: '100%', height: 'auto' }} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default IsiCaraPenggunaan;
