
import IsiTabelBerkas2 from "../components/IsiTabelBerkas2";
import Navbars2 from "../components/Navbars2";



const TabelBerkas2 = () => {
    return (
        <div>
        <Navbars2/>
        <IsiTabelBerkas2/>
        </div>
    )

}

export default TabelBerkas2;