import { useEffect, useState } from "react";
import "./landingPage.css";
import { Button, Card, Form, Modal } from "react-bootstrap";

const EditData3 = ({ show, handleClose, data, AddUser }) => {
  const [tiket_bag3, setTiket] = useState(data ? data.tiket_bag3 : "");
  const [tanggal_bag3, setTanggal] = useState(data ? data.tanggal_bag3 : "");
  const [internet_bag3, setInternet] = useState(data ? data.internet_bag3 : "");
  const [odp_bag3, setOdp] = useState(data ? data.odp_bag3 : "");

  const [duplicateData, setDuplicateData] = useState(null);

  useEffect(() => {
    if (data) {
      setTiket(data.tiket_bag3);
      setTanggal(data.tanggal_bag3);
      setInternet(data.internet_bag3);
      setOdp(data.odp_bag3);
    }
  }, [data]);


  useEffect(() => {
    fetch("http://api.databoqti.my.id/sto_boq_bag3")
      .then((res) => res.json())
      .then((data) => {
        const updatedData = data.map((d) => {
          const duplicates = data.filter(
            (item) => item.internet_bag3 === d.internet_bag3
          );
          if (duplicates.length > 1) {
            const dates = duplicates.map((item) => new Date(item.tanggal_bag3));
            const maxDate = new Date(Math.max.apply(null, dates));
            const minDate = new Date(Math.min.apply(null, dates));
            const monthsDifference =
              (maxDate.getFullYear() - minDate.getFullYear()) * 12 +
              maxDate.getMonth() -
              minDate.getMonth();

            let gangguan;
            if (monthsDifference >= 0 && monthsDifference <= 3) {
              gangguan = "★";
            } else if (monthsDifference > 3 && monthsDifference <= 6) {
              gangguan = "★★";
            } else if (monthsDifference > 6) {
              gangguan = "★★★";
            }

            return { ...d, gangguan };
          }
          return d;
        });

        setDuplicateData(updatedData.filter((d) => d.gangguan)); // Store data with 'gangguan'
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tiket_bag3) {
      alert("Tiket tidak boleh kosong");
      return;
    }
    await AddUser({ tiket_bag3, tanggal_bag3, internet_bag3, odp_bag3 });
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 style={{ fontWeight: "bold" }}>Keterangan Tiket</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
          {data && (
              <>
                <p>
                  <strong>No Internet:</strong> {data.internet_bag3} <br />
                  <strong>ODP:</strong> {data.odp_bag3} <br />
                  <strong>Tanggal:</strong>{" "}
                  {new Date(data.tanggal_bag3).toLocaleDateString("en-CA")}{" "}
                  <br />
                  <strong>Keterangan Gangguan :</strong>{" "}
                  {data.gangguan
                    ? data.gangguan === "★"
                      ? `Per-3 Bulan (${data.internet_bag3})`
                      : data.gangguan === "★★"
                      ? `Per-6 Bulan (${data.internet_bag3})`
                      : `Lebih dari 6 Bulan (${data.internet_bag3})`
                    : "-"}
                </p>
                <hr className="hr" />
                {duplicateData &&
                  duplicateData
                    .filter((item) => item.internet_bag3 === data.internet_bag3)
                    .map((item, index) => (
                      <p key={index}>
                        <strong>Data Duplikat #{index + 1}:</strong>{" "}
                        <br />
                        No Internet: {item.internet_bag3} <br />
                        ODP: {item.odp_bag3} <br />
                        Tanggal:{" "}
                        <strong>
                          {new Date(item.tanggal_bag3).toLocaleDateString(
                            "en-CA"
                          )}
                        </strong>
                      </p>
                    ))}
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="mt-3">
          <Card.Body>
            <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="font-weight-bold">Tiket</Form.Label>
            <Form.Control
              type="text"
              value={tiket_bag3}
              readOnly
              placeholder="IN121250385"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="font-weight-bold">Tanggal</Form.Label>
            <Form.Control
              type="date"
              value={tanggal_bag3}
              onChange={(e) => setTanggal(e.target.value)}
              placeholder="Contoh: 2023-05-31"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="font-weight-bold">No Internet</Form.Label>
            <Form.Control
              type="text"
              value={internet_bag3}
              onChange={(e) => setInternet(e.target.value)}
              placeholder="Contoh: 162504221963"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="font-weight-bold">ODP</Form.Label>
            <Form.Control
              type="text"
              value={odp_bag3}
              onChange={(e) => setOdp(e.target.value)}
              placeholder="Contoh: ODP-MLN-FL/31"
            />
          </Form.Group>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button variant="secondary" onClick={handleClose}>
                  Tutup
                </Button>
                <Button variant="dark" type="submit">
                  Simpan Perubahan
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default EditData3;
