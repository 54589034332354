import React from "react"; // Import React
import NavbarsUtama from "./NavbarsUtama"; // Import NavbarsUtama component
import "./landingPage.css";

const Home = () => {
  return (
    <div >
      <NavbarsUtama />
      <div className="myBG">
      <div className="container-fluid pt-5">
        <div className="row justify-content-center">
          <div className="col-md-3 col-sm-4">
            <div className="wrimagecard wrimagecard-topimage">
              <a href="/TabelBerkas">
                <div
                  className="wrimagecard-topimage_header"
                  style={{ backgroundColor: "rgba(187, 120, 36, 0.1)" }}
                >
                  <center>
                    <i className="fa fa-edit" style={{ color: "#BB7824" }}></i>
                  </center>
                </div>
                <div className="wrimagecard-topimage_title">
                  <h4 className="text-center fw-bolder">
                  Tabel BOQ 1
                    <div className="pull-right badge">18</div>
                  </h4>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="wrimagecard wrimagecard-topimage">
              <a href="/TabelBerkas2">
                <div
                  className="wrimagecard-topimage_header"
                  style={{ backgroundColor: "rgba(22, 160, 133, 0.1)" }}
                >
                  <center>
                    <i className="fa fa-pencil-square" style={{ color: "#16A085" }}></i>
                  </center>
                </div>
                <div className="wrimagecard-topimage_title">
                  <h4 className="text-center fw-bolder">
                  Tabel BOQ 2
                    <div className="pull-right badge" id="WrControls"></div>
                  </h4>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="wrimagecard wrimagecard-topimage">
              <a href="/TabelBerkas3">
                <div
                  className="wrimagecard-topimage_header"
                  style={{ backgroundColor: "rgba(213, 15, 37, 0.1)" }}
                >
                  <center>
                    <i className="fa fa-pencil" style={{ color: "#d50f25" }}></i>
                  </center>
                </div>
                <div className="wrimagecard-topimage_title">
                  <h4 className="text-center fw-bolder">
                    Tabel BOQ 3
                    <div className="pull-right badge" id="WrForms"></div>
                  </h4>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Home;
