import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Headroom from 'react-headroom';

const Navbars = () => {
    return (
        <>
        <Headroom>
          <Navbar bg="light" data-bs-theme="light">
            <Container>
              <Navbar.Brand href="/">
                <img
                  src="images/Telkom_Indonesialogo.png"
                  width="62"
                  height="34"
                  className="d-inline-block align-top"
                  alt=""
                  style={{ objectFit: 'contain' }}
                />
              </Navbar.Brand>
              <Nav className="me-auto">
              <Nav.Link href="/Home">Menu Utama</Nav.Link>
                <Nav.Link href="/TabelBerkas"><i class="fa fa-file" aria-hidden="true" style={{ color: "#BB7824" }}></i> Tabel Berkas</Nav.Link>
                <Nav.Link href="/UploadBerkas">Upload Berkas (B1)</Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          </Headroom>
        </>
      );
    }

export default Navbars;
