import { useEffect, useState } from "react";
import "./landingPage.css";
import { Button, Card, Form, Modal } from "react-bootstrap";

const EditData2 = ({ show, handleClose, data, AddUser }) => {
  const [tiket_bag2, setTiket] = useState(data ? data.tiket_bag2 : "");
  const [tanggal_bag2, setTanggal] = useState(data ? data.tanggal_bag2 : "");
  const [internet_bag2, setInternet] = useState(data ? data.internet_bag2 : "");
  const [odp_bag2, setOdp] = useState(data ? data.odp_bag2 : "");

  const [duplicateData, setDuplicateData] = useState(null);

  useEffect(() => {
    if (data) {
      setTiket(data.tiket_bag2);
      setTanggal(data.tanggal_bag2);
      setInternet(data.internet_bag2);
      setOdp(data.odp_bag2);
    }
  }, [data]);


  useEffect(() => {
    fetch("http://api.databoqti.my.id/sto_boq_bag2")
      .then((res) => res.json())
      .then((data) => {
        const updatedData = data.map((d) => {
          const duplicates = data.filter(
            (item) => item.internet_bag2 === d.internet_bag2
          );
          if (duplicates.length > 1) {
            const dates = duplicates.map((item) => new Date(item.tanggal_bag2));
            const maxDate = new Date(Math.max.apply(null, dates));
            const minDate = new Date(Math.min.apply(null, dates));
            const monthsDifference =
              (maxDate.getFullYear() - minDate.getFullYear()) * 12 +
              maxDate.getMonth() -
              minDate.getMonth();

            let gangguan;
            if (monthsDifference >= 0 && monthsDifference <= 3) {
              gangguan = "★";
            } else if (monthsDifference > 3 && monthsDifference <= 6) {
              gangguan = "★★";
            } else if (monthsDifference > 6) {
              gangguan = "★★★";
            }

            return { ...d, gangguan };
          }
          return d;
        });

        setDuplicateData(updatedData.filter((d) => d.gangguan)); // Store data with 'gangguan'
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tiket_bag2) {
      alert("Tiket tidak boleh kosong");
      return;
    }
    await AddUser({ tiket_bag2, tanggal_bag2, internet_bag2, odp_bag2 });
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 style={{ fontWeight: "bold" }}>Keterangan Tiket</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            {data && (
              <>
                <p>
                  <strong>No Internet:</strong> {data.internet_bag2} <br />
                  <strong>ODP:</strong> {data.odp_bag2} <br />
                  <strong>Tanggal:</strong>{" "}
                  {new Date(data.tanggal_bag2).toLocaleDateString("en-CA")}{" "}
                  <br />
                  <strong>Keterangan Gangguan :</strong>{" "}
                  {data.gangguan
                    ? data.gangguan === "★"
                      ? `Per-3 Bulan (${data.internet_bag2})`
                      : data.gangguan === "★★"
                      ? `Per-6 Bulan (${data.internet_bag2})`
                      : `Lebih dari 6 Bulan (${data.internet_bag2})`
                    : "-"}
                </p>
                <hr className="hr" />
                {duplicateData &&
                  duplicateData
                    .filter((item) => item.internet_bag2 === data.internet_bag2)
                    .map((item, index) => (
                      <p key={index}>
                        <strong>Data Duplikat #{index + 1}:</strong>{" "}
                        <br />
                        No Internet: {item.internet_bag2} <br />
                        ODP: {item.odp_bag2} <br />
                        Tanggal:{" "}
                        <strong>
                          {new Date(item.tanggal_bag2).toLocaleDateString(
                            "en-CA"
                          )}
                        </strong>
                      </p>
                    ))}
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="mt-3">
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="font-weight-bold">Tiket</Form.Label>
                <Form.Control
                  type="text"
                  value={tiket_bag2}
                  readOnly
                  placeholder="IN121250385"
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="font-weight-bold">Tanggal</Form.Label>
                <Form.Control
                  type="date"
                  value={tanggal_bag2}
                  onChange={(e) => setTanggal(e.target.value)}
                  placeholder="Contoh: 2023-05-31"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="font-weight-bold">
                  No Internet
                </Form.Label>
                <Form.Control
                  type="text"
                  value={internet_bag2}
                  onChange={(e) => setInternet(e.target.value)}
                  placeholder="Contoh: 162504221963"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="font-weight-bold">ODP</Form.Label>
                <Form.Control
                  type="text"
                  value={odp_bag2}
                  onChange={(e) => setOdp(e.target.value)}
                  placeholder="Contoh: ODP-MLN-FL/31"
                />
              </Form.Group>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button variant="secondary" onClick={handleClose}>
                  Tutup
                </Button>
                <Button variant="dark" type="submit">
                  Simpan Perubahan
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default EditData2;
