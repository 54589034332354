import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import "./landingPage.css";


const FilterMenu3 = ({show, handleClose, data, setData}) => {

// Function to sort data by start of the year
const sortByStartOfYear = (data) => {
  return data.sort((a, b) => {
    const yearA = new Date(a.tanggal_bag3).getFullYear();
    const yearB = new Date(b.tanggal_bag3).getFullYear();
    return yearA - yearB; // for ascending order
  });
};

// Function to sort data by end of the year
const sortByEndOfYear = (data) => {
  return data.sort((a, b) => {
    const yearA = new Date(a.tanggal_bag3).getFullYear();
    const yearB = new Date(b.tanggal_bag3).getFullYear();
    return yearB - yearA; // for descending order
  });
};

// Function to sort data by month
const sortByEndMonth = (data) => {
  return data.sort((a, b) => {
    const dateA = new Date(a.tanggal_bag3);
    const dateB = new Date(b.tanggal_bag3);
    const monthA = dateA.getMonth();
    const monthB = dateB.getMonth();
    const dayA = dateA.getDate();
    const dayB = dateB.getDate();

    if (monthB - monthA === 0) {
      return dayB - dayA; // sort by date if months are equal
    } else {
      return monthB - monthA; // sort by month
    }
  });
};

const sortByStartMonth = (data) => {
  return data.sort((a, b) => {
    const dateA = new Date(a.tanggal_bag3);
    const dateB = new Date(b.tanggal_bag3);
    const monthA = dateA.getMonth();
    const monthB = dateB.getMonth();
    const dayA = dateA.getDate();
    const dayB = dateB.getDate();

    if (monthA - monthB === 0) {
      return dayA - dayB; // sort by date if months are equal
    } else {
      return monthA - monthB; // sort by month
    }
  });
};

  const handleSortByStartOfYear = () => {
    const sortedData = sortByStartOfYear(data);
    setData(sortedData);
    handleClose();
  };
  
  const handleSortByEndOfYear = () => {
    const sortedData = sortByEndOfYear(data);
    setData(sortedData);
    handleClose();
  };
  
  const handleSortByStartMonth = () => {
    const sortedData = sortByStartMonth(data);
    setData(sortedData);
    handleClose();
  };
  
  const handleSortByMonth = () => {
    const sortedData = sortByEndMonth(data);
    setData(sortedData);
    handleClose();
  };

// Function to check internet
const handleCheckInternet = () => {
  // Create an object to count the occurrences of each internet number
  const counts = data.reduce((acc, curr) => {
    acc[curr.internet_bag3] = (acc[curr.internet_bag3] || 0) + 1;
    return acc;
  }, {});
    
  // Sort the data array based on the counts
  const sortedData = [...data].sort((a, b) => {
    if (counts[b.internet_bag3] !== counts[a.internet_bag3]) {
      return counts[b.internet_bag3] - counts[a.internet_bag3];
    }
    return a.internet_bag3 > b.internet_bag3 ? 1 : -1;
  });

  setData(sortedData);
  handleClose();
};

// Function to check odp
const handleCheckODP = () => {
  // Create an object to count the occurrences of each odp number
  const counts = data.reduce((acc, curr) => {
    acc[curr.odp_bag3] = (acc[curr.odp_bag3] || 0) + 1;
    return acc;
  }, {});

  const sortedData = [...data].sort((a, b) => counts[b.odp_bag3] - counts[a.odp_bag3]);
  setData(sortedData);
  handleClose();
};

    return (
      <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Data -</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="text-center">
          <Form.Group className="mb-3 d-grid gap-2">
          <Button variant="outline-dark" size="lg" onClick={handleSortByStartOfYear}>
        Berdasarkan Awal Tahun
      </Button>
          </Form.Group>
          <Form.Group className="mb-3 d-grid gap-2">
          <Button variant="outline-dark" size="lg" onClick={handleSortByEndOfYear}>
        Berdasarkan Akhir Tahun
      </Button>
          </Form.Group>
          <Form.Group className="mb-3 d-grid gap-2">
          <Button variant="outline-dark" size="lg" onClick={handleSortByStartMonth}>
        Berdasarkan Awal Bulan
      </Button>
          </Form.Group>
          <Form.Group className="mb-3 d-grid gap-2">
          <Button variant="outline-dark" size="lg" onClick={handleSortByMonth}>
        Berdasarkan Akhir Bulan
      </Button>
          </Form.Group>
          <Form.Group className="mb-3 d-grid gap-2">
          <Button variant="outline-dark" size="lg" onClick={handleCheckInternet}>
        Cek Internet
      </Button>
          </Form.Group>
          <Form.Group className="mb-3 d-grid gap-2">
          <Button variant="outline-dark" size="lg" onClick={handleCheckODP}>
        Cek ODP
      </Button>
          </Form.Group>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FilterMenu3;