import { useState } from "react";
import IsiUploadBerkas from "../components/IsiUploadBerkas";
import Navbar from "../components/Navbars";
import ExcelImport2 from "../components/ExcelImport2";
import Navbars2 from "../components/Navbars2";



const UploadBerkas2 = () => {
    return (
        <div>
        <Navbars2/>
        {/* <IsiUploadBerkas/> */}
        <ExcelImport2/>
        </div>
    )

}

export default UploadBerkas2;