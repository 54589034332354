import { useState } from "react";
import IsiUploadBerkas from "../components/IsiUploadBerkas";
import Navbar from "../components/Navbars";
import Navbars3 from "../components/Navbars3";
import ExcelImport3 from "../components/ExcelImport3";




const UploadBerkas3 = () => {
    return (
        <div>
        <Navbars3/>
        {/* <IsiUploadBerkas/> */}
        <ExcelImport3/>
        </div>
    )

}

export default UploadBerkas3;