import { Col, Container, Row, Button} from "react-bootstrap"
import "./landingPage.css";

const NotFoundPage = () => {
    return (
        <div>
            <div className="myBG">
                <div className="PageNotFound">
                <Container className="text-white d-flex justify-content-center align-items-center">
                    <Row>
                        <Col>
                        <h1>Halaman Tidak Ditemukan</h1>
                        </Col>
                    </Row>
                </Container>
                <Button variant="light" href='/' style={{position: 'absolute', top: '10px', left: '10px'}}>
                        Kembali Ke Halaman Utama
                    </Button>
            </div>
        </div>
    </div>
    )
}

export default NotFoundPage