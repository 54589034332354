import IsiCaraPenggunaan from "../components/IsiCaraPengunaan";
import NavbarsUtama from "./NavbarsUtama";



const CaraPenggunaan = () => {
    return (
        <div>
        <NavbarsUtama/>
        <IsiCaraPenggunaan/>
        </div>
    )

}

export default CaraPenggunaan;